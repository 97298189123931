h2, h3 {
    text-align: center;
}
/* img {
    display: grid;
    width: 30px;
    height: 20px;
} */
textarea {
    resize: none;
  }
